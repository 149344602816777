import { motion, useAnimation } from 'framer-motion';
import React from 'react';

export function CheckAnimation({
  enableAnimation,
}: {
  enableAnimation: boolean;
}): JSX.Element {
  const pathControls = useAnimation();
  const scaleControls = useAnimation();

  const startCheckAnimation = React.useCallback(async () => {
    await pathControls.start({
      strokeDashoffset: 0,
      transition: { duration: 0.3, ease: 'linear' },
    });
  }, [pathControls]);

  const showCheckView = React.useCallback(async () => {
    await scaleControls.start({
      scale: [0, 1.3, 0.9],
      transition: { type: 'spring', stiffness: 400, damping: 10 },
    });
  }, [scaleControls]);

  React.useEffect(() => {
    if (enableAnimation) {
      showCheckView();
      startCheckAnimation();
    }
  }, [enableAnimation, showCheckView, startCheckAnimation]);

  return (
    <motion.div
      initial={{ scale: 0 }}
      animate={scaleControls}
      className="absolute font-inter font-semibold text-white text-2xl pl-2 pb-2"
    >
      <svg width={50} height={50} viewBox="0 0 50 50">
        <motion.path
          d="M10 25 L20 35 L40 15"
          stroke="white"
          strokeWidth="5"
          fill="none"
          strokeLinecap="round"
          initial={{ strokeDasharray: 45, strokeDashoffset: 45 }}
          animate={pathControls}
        />
      </svg>
    </motion.div>
  );
}
