export const categoriesEnWithEmojis = [
  {
    categoryName: 'Business strategy',
    emoji: '📈',
  },
  {
    categoryName: 'Start-up',
    emoji: '🚀',
  },
  {
    categoryName: 'New Technology',
    emoji: '🔬',
  },
  {
    categoryName: 'Career',
    emoji: '👔',
  },
  {
    categoryName: 'Leadership',
    emoji: '👑',
  },
  {
    categoryName: 'Communication',
    emoji: '📢',
  },
  {
    categoryName: 'Entrepreneurship',
    emoji: '💼',
  },
  {
    categoryName: 'Personal growth',
    emoji: '🌱',
  },
  {
    categoryName: 'Business management',
    emoji: '📊',
  },
  {
    categoryName: 'Biography',
    emoji: '📚',
  },
  {
    categoryName: 'Finance and Wealth',
    emoji: '💵',
  },
  {
    categoryName: 'Marketing',
    emoji: '📈',
  },
  {
    categoryName: 'Productivity',
    emoji: '🚀',
  },
  {
    categoryName: 'Management',
    emoji: '📊',
  },
];
