import { useTranslation } from 'react-i18next';

import BackIcon from '~/components/BackIcon';
import { cn } from '~/utils/cn';

function HeaderStep({
  onBackClick,
  currentStep,
  allStep,
}: {
  onBackClick: () => void;
  currentStep: number;
  allStep: number;
}): JSX.Element {
  const { t } = useTranslation();

  const progress = (currentStep / allStep) * 100;

  return (
    <div className="absolute top-0 w-full">
      <div className="border-white border-b-2 border-opacity-10">
        <div className="flex items-center justify-left">
          <button
            className="flex items-center justify-center p-3 cursor-pointer pointer-events-auto"
            onClick={onBackClick}
          >
            <BackIcon />
          </button>
          <button
            className="flex items-center justify-center p-3 cursor-pointer pointer-events-auto"
            onClick={onBackClick}
          >
            <p
              className={cn(
                'font-inter text-white',
                'text-sm leading-4 letter-spacing-[-0.02em]',
                'cursor-pointer pointer-events-auto select-none'
              )}
            >
              {t('onboarding.back')}
            </p>
          </button>
        </div>
      </div>
      <div
        className={cn(
          'bg-lightGray3 h-[3px] w-[100%]',
          'transition-all duration-300 ease-in-out'
        )}
        style={{ width: `${progress}%` }}
      />
    </div>
  );
}

export default HeaderStep;
