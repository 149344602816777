import { motion } from 'framer-motion';

export default function Loader() {
  return (
    <motion.div
      initial={{ rotate: 0 }}
      animate={{ rotate: 360 }}
      transition={{ repeat: Infinity, duration: 1 }}
      style={{
        width: 20,
        height: 20,
        border: '2px solid #ddd',
        borderTop: '2px solid #3498db',
        borderRadius: '50%',
      }}
    />
  );
}
