import {
  type LoaderFunctionArgs,
  type MetaFunction,
  json,
} from '@remix-run/node';
import { useLoaderData } from '@remix-run/react';
import React from 'react';

import { getCollectionsByCategoryIds } from '~/business/getCollectionsByCategoryIds';
import { getMinimalBooksByNewCollectionIds } from '~/business/getMinimalBooksByNewCollectionIds';
import type { BookMinimalData } from '~/models/book';
import type { NewCollection } from '~/models/newCollection';
import { getPrice } from '~/modules/stripe/handlers/getPrice';
import { useIsPremium } from '~/store/AuthStore';
import {
  useAddAllCategories,
  useAllCategories,
  useIsOnOnboarding,
} from '~/store/onboarding';

import { getCategories } from '../business/getCategories';
import { getFirstFiveBooks } from '../business/getFirstFiveBooks';
import { getSecondFiveBooks } from '../business/getSecondFiveBooks';
import i18next from '../i18next.server';
import type { Locale } from '../modules/language/@types/Locales';

import Home from './components/Home/Home';
import OnboardingModal from './components/Onboarding/OnboardingModal';

export async function loader({ request }: LoaderFunctionArgs) {
  const locale = (await i18next.getLocale(request)) as Locale;
  const t = await i18next.getFixedT(request, 'common');
  const url = new URL(request.url);

  const collections: NewCollection[] = [];
  const categoryIds = url.searchParams.get('categoryIds');
  if (categoryIds) {
    const categoryIdsArray = decodeURIComponent(categoryIds).split(',');

    const strapiCollections = await getCollectionsByCategoryIds({
      locale,
      categoryIds: categoryIdsArray,
    });

    if (strapiCollections.length > 0) {
      collections.push(...strapiCollections);
    }
  }

  const books: BookMinimalData[] = [];
  const collectionIds = url.searchParams.get('collectionIds');
  if (collectionIds) {
    const collectionIdsArray = decodeURIComponent(collectionIds).split(',');

    const strapiBooks = await getMinimalBooksByNewCollectionIds({
      locale,
      collectionIds: collectionIdsArray,
    });

    if (strapiBooks.length > 0) {
      books.push(...strapiBooks);
    }
  }

  const firstBooks = await getFirstFiveBooks(locale);
  if (!firstBooks) {
    throw new Response('[Home] Get first five books failed', { status: 404 });
  }

  const secondBooks = await getSecondFiveBooks(locale);
  if (!secondBooks) {
    throw new Response('[Home] Get second five books failed', { status: 404 });
  }

  const categories = await getCategories(locale);
  if (!categories) {
    throw new Response('[Home] Get categories failed', { status: 404 });
  }

  const price = await getPrice();

  const amount = price.data[0].unit_amount
    ? price.data[0].unit_amount / 100
    : 0;
  const currency = price.data[0].currency || 'usd';
  const currencySymbol = currency.toUpperCase() === 'EUR' ? '€' : '$';

  const priceId = price.data[0].id;
  const priceLabel = `${amount.toFixed(2)} ${currencySymbol}`;
  const duration = price.data[0].recurring?.interval;

  const meta = { title: t('favicon.title') };

  return json({
    collections,
    books,
    firstBooks,
    secondBooks,
    categories,
    meta,
    locale,
    priceId,
    priceLabel,
    duration,
  });
}

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  const canonicalUrl = `https://dygest.co`;

  return [
    { title: data?.meta.title },
    {
      tagName: 'link',
      rel: 'canonical',
      href: canonicalUrl,
    },
  ];
};

export default function Index() {
  const { categories } = useLoaderData<typeof loader>();
  const isPremium = useIsPremium();

  const isOnOnboarding = useIsOnOnboarding();

  const allCategories = useAllCategories();
  const addAllCategories = useAddAllCategories();

  React.useEffect(() => {
    if (
      !isOnOnboarding &&
      categories.length > 0 &&
      allCategories.length === 0
    ) {
      addAllCategories(categories);
    }
  }, [isOnOnboarding, categories, allCategories, addAllCategories]);

  return (
    <>
      <Home />
      {isOnOnboarding && !isPremium && <OnboardingModal />}
    </>
  );
}
