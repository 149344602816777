export const categoriesFrWithEmojis = [
  {
    categoryName: 'Philosophie',
    emoji: '🧠',
  },
  {
    categoryName: 'Politique',
    emoji: '🗳️',
  },
  {
    categoryName: 'Psychologie',
    emoji: '🧑‍⚕️',
  },
  {
    categoryName: 'Relations et famille',
    emoji: '👨‍👩‍👦',
  },
  {
    categoryName: 'Religion et spiritualité',
    emoji: '🕊️',
  },
  {
    categoryName: 'Santé et bien-être',
    emoji: '🩺',
  },
  {
    categoryName: 'Science et technologie',
    emoji: '🚀',
  },
  {
    categoryName: 'Société',
    emoji: '🏙️',
  },
  {
    categoryName: 'Argent',
    emoji: '💵',
  },
  {
    categoryName: 'Activisme',
    emoji: '📢',
  },
  {
    categoryName: 'Art',
    emoji: '🖼️',
  },
  {
    categoryName: 'Biographies',
    emoji: '🧑‍🏫',
  },
  {
    categoryName: 'Business',
    emoji: '💼',
  },
  {
    categoryName: 'Développement personnel',
    emoji: '📚',
  },
  {
    categoryName: 'Droit',
    emoji: '🏛️',
  },
  {
    categoryName: 'Histoire',
    emoji: '⏳',
  },
  {
    categoryName: 'Nature et environnement',
    emoji: '🌳',
  },
];
