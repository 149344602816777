import { useLoaderData } from '@remix-run/react';
import { motion } from 'framer-motion';
import React from 'react';

import type { NewCollection } from '~/models/newCollection';
import type { loader } from '~/routes/_index';
import {
  useAddAllCollections,
  useAllCollections,
  useDeselectCollectionId,
  useSelectCollectionId,
  useSelectedCollectionIds,
} from '~/store/onboarding';
import { cn } from '~/utils/cn';

function CollectionsStep(): JSX.Element {
  const { collections: strapiCollections } = useLoaderData<typeof loader>();

  const allCollections = useAllCollections();
  const addAllCollections = useAddAllCollections();

  const [currentCollections, setCurrentCollections] = React.useState<
    NewCollection[]
  >([]);

  React.useEffect(
    () => {
      if (
        strapiCollections.length > 0 &&
        currentCollections.length === 0 &&
        allCollections.length === 0
      ) {
        addAllCollections(strapiCollections);
        setCurrentCollections(strapiCollections);
      } else {
        const currentCollectionIds = currentCollections.map(
          (collection) => collection.id
        );

        // remove duplicates
        const uniqueCollections = strapiCollections.filter(
          (collection) => !currentCollectionIds.includes(collection.id)
        );

        if (uniqueCollections.length === 0) {
          addAllCollections(strapiCollections);
          setCurrentCollections(strapiCollections);
        } else {
          addAllCollections(uniqueCollections);
          setCurrentCollections(uniqueCollections);
        }
      }
    },
    [] /* eslint-disable-line react-hooks/exhaustive-deps */
  );

  return (
    <div className="flex flex-wrap p-4 justify-around">
      {currentCollections.map((collection, index) => (
        <CollectionItem key={index} collection={collection} />
      ))}
    </div>
  );
}

export default CollectionsStep;

/* components */
function CollectionItem({
  collection,
}: {
  collection: NewCollection;
}): JSX.Element {
  const selectedCollectionIds = useSelectedCollectionIds();
  const selectCollectionId = useSelectCollectionId();
  const deselectCollectionId = useDeselectCollectionId();

  const isSelected = selectedCollectionIds.some(
    (selectedCollectionId) => selectedCollectionId === collection.id
  );

  const [selected, setSelected] = React.useState(isSelected);

  const onPress = React.useCallback(() => {
    setSelected(!selected);

    if (isSelected) {
      deselectCollectionId(collection.id);
    } else {
      selectCollectionId(collection.id);
    }
  }, [
    selected,
    collection,
    isSelected,
    selectCollectionId,
    deselectCollectionId,
  ]);

  return (
    <motion.div
      className="flex flex-col w-40 items-center gap-2 mb-2"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5, ease: 'easeInOut' }}
    >
      <button
        className={cn(
          'flex flex-col justify-center',
          'w-40 h-60 rounded-[7.5px]',
          'cursor-pointer border-[3px] border-transparent hover:bg-[#404040]',
          selected && 'border-white bg-[#404040]'
        )}
        onClick={onPress}
      >
        <img
          src={collection.coverUrl}
          alt={collection.name}
          className="w-full h-full object-cover rounded-[7.5px] select-none pointer-events-none"
        />
      </button>

      <p
        className={cn(
          'font-inter text-primary text-sm font-normal leading-20 text-center select-none',
          selected && 'text-white'
        )}
      >
        {collection.name}
      </p>
    </motion.div>
  );
}
