import { useLoaderData, useNavigate } from '@remix-run/react';
import { motion } from 'framer-motion';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Category } from '~/models/category';
import type { Locale } from '~/modules/language/@types/Locales';
import type { loader } from '~/routes/_index';
import {
  useAddAllPersonalities,
  useAllPersonalities,
  useSelectedCategories,
  useSelectedCollectionIds,
  useSelectedPersonalityIds,
} from '~/store/onboarding';
import { cn } from '~/utils/cn';
import useRootData from '~/utils/root';

import { useComputeAndAddPersonalities } from '../hooks/useComputePersonalities';

import BooksStep from './components/BooksStep';
import CategoriesStep from './components/CategoriesStep';
import CollectionsStep from './components/CollectionsStep';
import FooterStep from './components/FooterStep';
import HeaderStep from './components/HeaderStep';
import PersonalitiesStep from './components/PersonalitiesStep';

function Steps({
  onBackClick,
  onFinish,
}: {
  onBackClick: () => void;
  onFinish: () => void;
}): JSX.Element {
  const { t } = useTranslation();
  const rootData = useRootData();
  const currentLocale = rootData?.locale as Locale;
  const { categories } = useLoaderData<typeof loader>();

  const selectedCategories = useSelectedCategories();
  const allPersonalities = useAllPersonalities();
  const addAllPersonalities = useAddAllPersonalities();
  const selectedPersonalityIds = useSelectedPersonalityIds();
  const selectedCollectionIds = useSelectedCollectionIds();

  const selectedCategoryIds = selectedCategories.map((category) => category.id);

  const navigate = useNavigate();

  const currentSelectedCategories = computeCategoriesFromSelectedCategoryIds(
    categories,
    selectedCategoryIds
  );

  const currentPersonalities = useComputeAndAddPersonalities(
    currentSelectedCategories,
    currentLocale
  );

  const handleFetchCollections = React.useCallback((): void => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlParamsCategoryIds = urlParams.get('categoryIds');

    const selectedCategoryIdsJoined = selectedCategoryIds.join(',');
    const encoderUri = encodeURIComponent(selectedCategoryIdsJoined);

    const categoryIdsParam = `categoryIds=${encoderUri}`;

    if (urlParamsCategoryIds) {
      const updatedUrl = new URL(window.location.href);
      updatedUrl.searchParams.set('categoryIds', encoderUri);

      navigate(updatedUrl.pathname + updatedUrl.search, { replace: true });
    } else {
      const url = urlParams.toString()
        ? `${window.location.search}&${categoryIdsParam}`
        : `?${categoryIdsParam}`;

      navigate(url);
    }
  }, [selectedCategoryIds, navigate]);

  const handleFetchBooks = React.useCallback((): void => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlParamsCollectionIds = urlParams.get('collectionIds');

    const selectedCollectionIdsJoined = selectedCollectionIds.join(',');
    const encoderUri = encodeURIComponent(selectedCollectionIdsJoined);

    const collectionIdsParam = `collectionIds=${encoderUri}`;

    if (urlParamsCollectionIds) {
      const updatedUrl = new URL(window.location.href);
      updatedUrl.searchParams.set('collectionIds', encoderUri);

      navigate(updatedUrl.pathname + updatedUrl.search, { replace: true });
    } else {
      const url = urlParams.toString()
        ? `${window.location.search}&${collectionIdsParam}`
        : `?${collectionIdsParam}`;

      navigate(url);
    }
  }, [selectedCollectionIds, navigate]);

  const [currentStep, setCurrentStep] = React.useState(1);

  const onPressBack = React.useCallback(() => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    } else {
      onBackClick();
    }
  }, [currentStep, onBackClick]);

  const onPressContinue = React.useCallback(() => {
    if (currentStep < ALL_STEPS) {
      setCurrentStep(currentStep + 1);

      if (currentStep === 1 && selectedCategories.length > 2) {
        const uniquePersonalities = currentPersonalities.filter(
          (personality) => !allPersonalities.includes(personality)
        );

        if (uniquePersonalities.length > 0) {
          addAllPersonalities(uniquePersonalities);
        }

        handleFetchCollections();
      }

      if (currentStep === 3 && selectedCollectionIds.length > 2) {
        handleFetchBooks();
      }
    }

    if (currentStep === ALL_STEPS) {
      onFinish();
    }
  }, [
    currentStep,
    currentPersonalities,
    addAllPersonalities,
    allPersonalities,
    selectedCategories,
    handleFetchCollections,
    selectedCollectionIds,
    handleFetchBooks,
    onFinish,
  ]);

  const isCategoriesSelected =
    selectedCategoryIds.length < 3 && currentStep === 1;
  const isPersonalitiesSelected =
    selectedPersonalityIds.length < 3 && currentStep === 2;
  const isCollectionsSelected =
    selectedCollectionIds.length < 3 && currentStep === 3;

  return (
    <motion.div
      className={cn(
        'relative flex flex-col rounded-xl mx-auto shadow-lg',
        'md:h-[95vh] sm:h-[80vh] h-[80vh]',
        'xl:w-[40vw] lg:w-[50vw] md:w-[60vw] sm:w-[100vw] w-[100vw]'
      )}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5, ease: 'easeInOut' }}
    >
      <HeaderStep
        onBackClick={onPressBack}
        currentStep={currentStep}
        allStep={ALL_STEPS}
      />
      <div className={'flex-1 mt-16 mb-16 overflow-y-auto scrollbar-custom'}>
        <div className="flex flex-col justify-left p-4 gap-4 select-none">
          <h2
            className={cn(
              'font-sFProText text-white text-opacity-60 text-left',
              'xl:text-sm lg:text-sm md:text-xs sm:text-xs text-xs'
            )}
          >
            {t('onboarding.step', {
              step: currentStep,
              allSteps: ALL_STEPS,
            }).toUpperCase()}
          </h2>
          <p
            className={cn(
              'font-nY font-bold text-white text-left',
              'xl:text-3xl lg:text-3xl md:text-2xl sm:text-2xl text-2xl'
            )}
          >
            {t(normalizeTitleStep(currentStep))}
          </p>
        </div>
        {currentStep === 1 && <CategoriesStep />}
        {currentStep === 2 && <PersonalitiesStep />}
        {currentStep === 3 && <CollectionsStep />}
        {currentStep === 4 && <BooksStep onFinish={onFinish} />}
      </div>
      <FooterStep
        onPress={onPressContinue}
        disabled={
          isCategoriesSelected ||
          isPersonalitiesSelected ||
          isCollectionsSelected
        }
      />
    </motion.div>
  );
}

export default Steps;

/* constants */
const ALL_STEPS = 4;

function computeCategoriesFromSelectedCategoryIds(
  categories: Category[],
  selectedCategoryIds: string[]
) {
  const currentCategories: Category[] = [];

  for (const categoryId of selectedCategoryIds) {
    const category = categories.find((category) => category.id === categoryId);

    if (category) {
      currentCategories.push(category);
    }
  }

  return currentCategories;
}

/* utils */
function normalizeTitleStep(step: number) {
  switch (step) {
    case 1:
      return 'onboarding.stepOne.title';
    case 2:
      return 'onboarding.stepTwo.title';
    case 3:
      return 'onboarding.stepThree.title';
    case 4:
      return 'onboarding.stepFour.title';
    default:
      return 'onboarding.stepOne.title';
  }
}
