import { getAnalytics } from 'firebase/analytics';
import { type FirebaseOptions, initializeApp } from 'firebase/app';
import { GoogleAuthProvider, OAuthProvider, getAuth } from 'firebase/auth';

const firebaseConfig: FirebaseOptions = {
  apiKey: 'AIzaSyCW3zfRulMx_rb7LMHb8jRdFRztIeNM-ho',
  authDomain: 'dygest-prod.firebaseapp.com',
  databaseURL: 'https://dygest-prod.firebaseio.com',
  projectId: 'dygest-prod',
  storageBucket: 'dygest-prod.appspot.com',
  messagingSenderId: '1081380527719',
  appId: '1:1081380527719:web:3e16243ed571a610657ec2',
  measurementId: 'G-YRK2EJMST4',
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);

const googleAuthProvider = new GoogleAuthProvider();
googleAuthProvider.addScope('email');
googleAuthProvider.addScope('profile');
googleAuthProvider.setCustomParameters({
  prompt: 'consent', // Forces the user to consent each time
});

const appleAuthProvider = new OAuthProvider('apple.com');
appleAuthProvider.addScope('email');
appleAuthProvider.addScope('name');

export { app, analytics, auth, googleAuthProvider, appleAuthProvider };
