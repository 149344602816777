import { useTranslation } from 'react-i18next';

export function PlayerElement() {
  return (
    <div
      className="relative hidden lg:flex mt-16 mb-16 bg-white rounded-3xl
                xl:mx:32 lg:mx-32 md:mx-16 sm:mx-8 xs:mx-4 mx-4
                xl:h-[45vh] lg:h-[45vh] md:h-[64vh] h-[64vh]
                xl:flex-row lg:flex-row md:flex-col sm:flex-col xs:flex-col flex-col
                xl:justify-evenly lg:justify-between md:justify-center justify-center"
    >
      <Player />
    </div>
  );
}

function Player() {
  const { t } = useTranslation();

  return (
    <div className="relative flex flex-row w-full rounded-3xl">
      <div className="hidden lg:flex flex-col justify-between p-8">
        <h3
          className="font-sfProText text-black font-weight-600 letter-spacing-[0.03em] text-left
                        2xl:text-3xl xl:text-xl lg:text-lg text-lg
                        2xl:leading-12 xl:leading-8 lg:leading-7"
        >
          {t('home.playerElement.title')}
        </h3>
        <h1
          className="font-nY font-bold text-black text-left letter-spacing-[0.02em]
                        2xl:text-5xl xl:text-3xl text-3xl
                        2xl:leading-16 xl:leading-12 leading-12"
        >
          {t('home.playerElement.subtitle')}
        </h1>
      </div>
      <div
        className="relative hidden lg:flex flex-row
                    xl:w-full lg:w-[30vw] md:w-[55vw] sm:w-[55vw] w-[55vw]
                    xl:ml-64 lg:ml-32 md:ml-16 sm:ml-8 xs:ml-4 ml-4 
                    rounded-tr-3xl rounded-br-3xl"
      >
        <img
          src="/images/home/player/player-one.png"
          alt="player-one"
          className="z-10"
        />
        <img
          src="/images/home/player/reading.png"
          alt="reading"
          className="relative hidden xl:block pt-8
                      2xl:left-[-80px] xl:left-[-130px] left-[-130px]"
        />
      </div>
    </div>
  );
}
