import { Link, useLoaderData } from '@remix-run/react';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import type { loader } from '~/routes/_index';
import useRootData from '~/utils/root';

export function Catalogue() {
  const { t } = useTranslation();
  const { secondBooks } = useLoaderData<typeof loader>();
  const { locale } = useRootData();

  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const scrollElement = scrollRef.current;
    if (scrollElement) {
      const center =
        (scrollElement.scrollWidth - scrollElement.clientWidth) / 2;
      scrollElement.scrollTo({
        left: center,
        behavior: 'smooth',
      });
    }
  }, []);

  return (
    <div className="relative container max-w-full mt-16">
      <div
        className="relative flex flex-col bg-background rounded-3xl mt-32
                      xl:mx-32 lg:mx-32 md:mx-16 sm:mx-8 xs:mx-4 mx-4 
                      xl:h-[120vh] lg:h-[100vh] md:h-[100vh] sm:h-[90vh] xs:h-[90vh] h-[90vh]
                      xl:gap-22 lg:gap-22 md:gap-16 sm:gap-16 xs:gap-5 gap-5
                      xl:p-16 lg:p-16 md:p-12 sm:p-12 xs:p-8 p-8
                      border-[1px] border-solid border-white border-opacity-[6%]"
      >
        <div className="relative flex flex-row items-center justify-center gap-4">
          <img
            src="/images/home/catalogue/flower-left.svg"
            alt="flower left"
            className="hidden md:flex"
          />
          <h1
            className="font-nY font-bold text-center text-white
                      xl:text-5xl lg:text-5xl md:text-4xl sm:text-3xl xs:text-2xl text-2xl
                      xl:leading-12 lg:leading-12 md:leading-10 sm:leading-8 xs:leading-7 leading-7"
          >
            {t('home.catalog.title')}
          </h1>
          <img
            src="/images/home/catalogue/flower-right.svg"
            alt="flower right"
            className="hidden md:flex"
          />
        </div>
        <h3
          className="font-sfProText font-normal text-center text-white text-opacity-[70%] mt-4
                    xl:text-2xl lg:text-2xl md:text-xl sm:text-lg xs:text-lg text-lg
                    xl:leading-7 lg:leading-7 md:leading-6 sm:leading-5 xs:leading-5 leading-5"
        >
          {t('home.catalog.subtitle')}
        </h3>
      </div>
      <div className="absolute max-w-full bottom-12">
        <div
          ref={scrollRef}
          className="flex overflow-x-auto space-x-4 scrollbar-none scroll-touch justify-center py-4"
        >
          {secondBooks.map((book) => (
            <Link
              key={book.id}
              className="flex-none shadow-2xl rounded-[4.18px]
                        border-[1px] border-solid border-white border-opacity-[6%]
                        xl:w-1/6 lg:w-1/5 md:w-1/4 sm:w-1/3 xs:w-1/3 w-1/3
                        cursor-pointer hover:scale-105 transition-transform duration-300 ease-in-out"
              to={`/${book.authorSlug}/${book.slug}?lng=${locale}`}
            >
              <img
                src={book.coverUrl}
                alt=""
                className="rounded-[4.18px] w-full object-cover"
              />
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}
