import { motion } from 'framer-motion';

import { cn } from '~/utils/cn';

import type { Landings } from '../OnboardingModal';

function LandingTwo({ content }: { content: Landings }) {
  return (
    <motion.div
      className="flex flex-col mb-4 select-none"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5, ease: 'easeInOut' }}
    >
      <h2
        className={cn(
          'font-nY text-left text-white',
          'xl:text-3xl lg:text-3xl md:text-2xl sm:text-xl text-xl',
          'xl:leading-7 lg:leading-7 md:leading-5 sm:leading-5 xs:leading-4 leading-4',
          'mb-4'
        )}
      >
        {content.title}
      </h2>
      <div className="mb-4 text-left">
        <p
          className={cn(
            'font-sFProText text-left text-white',
            'xl:text-lg lg:text-lg md:text-md sm:text-sm text-sm',
            'xl:leading-5 lg:leading-5 md:leading-4 sm:leading-3 xs:leading-3 leading-3',
            'inline'
          )}
        >
          {content.description}
        </p>
        <p
          className={cn(
            'font-sFProText font-bold text-left text-white',
            'xl:text-lg lg:text-lg md:text-md sm:text-sm text-sm',
            'xl:leading-5 lg:leading-5 md:leading-4 sm:leading-3 xs:leading-3 leading-3',
            'inline'
          )}
        >
          {content.descriptionBold}
        </p>
        <p
          className={cn(
            'font-sFProText text-left text-white',
            'xl:text-lg lg:text-lg md:text-md sm:text-sm text-sm',
            'xl:leading-5 lg:leading-5 md:leading-4 sm:leading-3 xs:leading-3 leading-3',
            'inline'
          )}
        >
          {content.descriptionTwo}
        </p>
        <p
          className={cn(
            'font-sFProText font-bold text-left text-white',
            'xl:text-lg lg:text-lg md:text-md sm:text-sm text-sm',
            'xl:leading-5 lg:leading-5 md:leading-4 sm:leading-3 xs:leading-3 leading-3',
            'inline'
          )}
        >
          {content.descriptionTwoBold}
        </p>
        <p
          className={cn(
            'font-sFProText text-left text-white',
            'xl:text-lg lg:text-lg md:text-md sm:text-sm text-sm',
            'xl:leading-5 lg:leading-5 md:leading-4 sm:leading-3 xs:leading-3 leading-3',
            'inline'
          )}
        >
          {content.descriptionThree}
        </p>
        <p
          className={cn(
            'font-sFProText font-bold text-left text-white',
            'xl:text-lg lg:text-lg md:text-md sm:text-sm text-sm',
            'xl:leading-5 lg:leading-5 md:leading-4 sm:leading-3 xs:leading-3 leading-3',
            'inline'
          )}
        >
          {content.descriptionThreeBold}
        </p>
        <p
          className={cn(
            'font-sFProText text-left text-white',
            'xl:text-lg lg:text-lg md:text-md sm:text-sm text-sm',
            'xl:leading-5 lg:leading-5 md:leading-4 sm:leading-3 xs:leading-3 leading-3',
            'inline'
          )}
        >
          {content.descriptionFour}
        </p>
        <p
          className={cn(
            'font-sFProText font-bold text-left text-white',
            'xl:text-lg lg:text-lg md:text-md sm:text-sm text-sm',
            'xl:leading-5 lg:leading-5 md:leading-4 sm:leading-3 xs:leading-3 leading-3',
            'inline'
          )}
        >
          {content.descriptionFourBold}
        </p>
      </div>
      <div className="text-left">
        <p
          className={cn(
            'font-sFProText text-left text-white',
            'xl:text-lg lg:text-lg md:text-md sm:text-sm text-sm',
            'xl:leading-5 lg:leading-5 md:leading-4 sm:leading-3 xs:leading-3 leading-3',
            'inline'
          )}
        >
          {content.nextDescription}
        </p>
        <p
          className={cn(
            'font-sFProText font-bold text-left text-white',
            'xl:text-lg lg:text-lg md:text-md sm:text-sm text-sm',
            'xl:leading-5 lg:leading-5 md:leading-4 sm:leading-3 xs:leading-3 leading-3',
            'inline'
          )}
        >
          {content.nextDescriptionBold}
        </p>
        <p
          className={cn(
            'font-sFProText text-left text-white',
            'xl:text-lg lg:text-lg md:text-md sm:text-sm text-sm',
            'xl:leading-5 lg:leading-5 md:leading-4 sm:leading-3 xs:leading-3 leading-3',
            'inline'
          )}
        >
          {content.nextDescriptionTwo}
        </p>
        <p
          className={cn(
            'font-sFProText font-bold text-left text-white',
            'xl:text-lg lg:text-lg md:text-md sm:text-sm text-sm',
            'xl:leading-5 lg:leading-5 md:leading-4 sm:leading-3 xs:leading-3 leading-3',
            'inline'
          )}
        >
          {content.nextDescriptionTwoBold}
        </p>
        <p
          className={cn(
            'font-sFProText text-left text-white',
            'xl:text-lg lg:text-lg md:text-md sm:text-sm text-sm',
            'xl:leading-5 lg:leading-5 md:leading-4 sm:leading-3 xs:leading-3 leading-3',
            'inline'
          )}
        >
          {content.nextDescriptionThree}
        </p>
        <p
          className={cn(
            'font-sFProText font-bold text-left text-white',
            'xl:text-lg lg:text-lg md:text-md sm:text-sm text-sm',
            'xl:leading 5 lg:leading-5 md:leading-4 sm:leading-3 xs:leading-3 leading-3',
            'inline'
          )}
        >
          {content.nextDescriptionThreeBold}
        </p>
        <p
          className={cn(
            'font-sFProText text-left text-white',
            'xl:text-lg lg:text-lg md:text-md sm:text-sm text-sm',
            'xl:leading-5 lg:leading-5 md:leading-4 sm:leading-3 xs:leading-3 leading-3',
            'inline'
          )}
        >
          {content.nextDescriptionFour}
        </p>
      </div>
    </motion.div>
  );
}

export default LandingTwo;
