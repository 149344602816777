export const ACTIVISME = [
  {
    id: '1',
    name: 'Naomi Klein',
    thumbnailUrl: '/images/portraits/naomi_klein.png',
  },
  {
    id: '2',
    name: 'Simone De Beauvoir',
    thumbnailUrl: '/images/portraits/simone_de_beauvoir.png',
  },
  {
    id: '3',
    name: 'Simone Veil',
    thumbnailUrl: '/images/portraits/simone_veil.png',
  },
];

export const ARGENT = [
  {
    id: '4',
    name: 'Warren Buffett',
    thumbnailUrl: '/images/portraits/warren_buffett.png',
  },
  {
    id: '5',
    name: 'Thomas Piketty',
    thumbnailUrl: '/images/portraits/thomas_piketty.png',
  },
  {
    id: '6',
    name: 'Robert Kiyosaki',
    thumbnailUrl: '/images/portraits/robert_kiyosaki.png',
  },
];

export const ART = [
  {
    id: '7',
    name: 'Jean-Paul Sartre',
    thumbnailUrl: '/images/portraits/jean_paul_sartre.png',
  },
  {
    id: '8',
    name: 'Mozart',
    thumbnailUrl: '/images/portraits/mozart.png',
  },
  {
    id: '9',
    name: 'Stephen King',
    thumbnailUrl: '/images/portraits/stephen_king.png',
  },
];

export const BIOGRAPHIES = [
  {
    id: '10',
    name: 'Steve Jobs',
    thumbnailUrl: '/images/portraits/steve_jobs.png',
  },
  {
    id: '11',
    name: 'Winslow Churchill',
    thumbnailUrl: '/images/portraits/winston_churchill.png',
  },
  {
    id: '12',
    name: 'Charles De Gaulle',
    thumbnailUrl: '/images/portraits/charles_de_gaulle.png',
  },
];

export const BUSINESS = [
  {
    id: '13',
    name: 'Jack Ma',
    thumbnailUrl: '/images/portraits/jack_ma.png',
  },
  {
    id: '14',
    name: 'Jeff Bezos',
    thumbnailUrl: '/images/portraits/jeff_bezos.png',
  },
  {
    id: '15',
    name: 'Seth Godin',
    thumbnailUrl: '/images/portraits/seth_godin.png',
  },
];

export const DEVELOPPEMENT_PERSONNEL = [
  {
    id: '16',
    name: 'Frédéric Lenoir',
    thumbnailUrl: '/images/portraits/frederic_lenoir.png',
  },
  {
    id: '17',
    name: 'Idriss Aberkane',
    thumbnailUrl: '/images/portraits/idriss_aberkane.png',
  },
  {
    id: '18',
    name: 'Robert Cialdini',
    thumbnailUrl: '/images/portraits/robert_cialdini.png',
  },
];

export const DROIT = [
  {
    id: '19',
    name: 'Jean-Jacques Rousseau',
    thumbnailUrl: '/images/portraits/jean_jacques_rousseau.png',
  },
  {
    id: '20',
    name: 'Karl Marx',
    thumbnailUrl: '/images/portraits/karl_marx.png',
  },
  {
    id: '21',
    name: 'Michel Foucault',
    thumbnailUrl: '/images/portraits/michel_foucault.png',
  },
];

export const HISTOIRE = [
  {
    id: '22',
    name: 'Napoléon',
    thumbnailUrl: '/images/portraits/napoleon.png',
  },
  {
    id: '23',
    name: 'Staline',
    thumbnailUrl: '/images/portraits/staline.png',
  },
  {
    id: '24',
    name: 'Yuval Noah Harari',
    thumbnailUrl: '/images/portraits/yuval_noah_harari.png',
  },
];

export const NATURE_ET_ENVIRONNEMENT = [
  {
    id: '25',
    name: 'Hans Jonas',
    thumbnailUrl: '/images/portraits/hans_jonas.png',
  },
  {
    id: '1',
    name: 'Naomi Klein',
    thumbnailUrl: '/images/portraits/naomi_klein.png',
  },
  {
    id: '27',
    name: 'Boris Cyrulnik',
    thumbnailUrl: '/images/portraits/boris_cyrulnik.png',
  },
];

export const PHILOSOPHIE = [
  {
    id: '28',
    name: 'Aristote',
    thumbnailUrl: '/images/portraits/aristote.png',
  },
  {
    id: '29',
    name: 'Platon',
    thumbnailUrl: '/images/portraits/platon.png',
  },
  {
    id: '30',
    name: 'Spinoza',
    thumbnailUrl: '/images/portraits/spinoza.png',
  },
];

export const POLITIQUE = [
  {
    id: '31',
    name: 'Hannah Arendt',
    thumbnailUrl: '/images/portraits/hannah_arendt.png',
  },
  {
    id: '20',
    name: 'Karl Marx',
    thumbnailUrl: '/images/portraits/karl_marx.png',
  },
  {
    id: '33',
    name: 'Montesquieu',
    thumbnailUrl: '/images/portraits/montesquieu.png',
  },
];

export const PSYCHOLOGIE = [
  {
    id: '34',
    name: 'Carl Jung',
    thumbnailUrl: '/images/portraits/carl_jung.png',
  },
  {
    id: '35',
    name: 'Sigmund Freud',
    thumbnailUrl: '/images/portraits/sigmund_freud.png',
  },
  {
    id: '36',
    name: 'Dale Carnegie',
    thumbnailUrl: '/images/portraits/dale_carnegie.png',
  },
];

export const RELATIONS_ET_FAMILLE = [
  {
    id: '27',
    name: 'Boris Cyrulnik',
    thumbnailUrl: '/images/portraits/boris_cyrulnik.png',
  },
  {
    id: '38',
    name: 'Donald Winnicott',
    thumbnailUrl: '/images/portraits/donald_winnicott.png',
  },
  {
    id: '39',
    name: 'Françoise Dolto',
    thumbnailUrl: '/images/portraits/francoise_dolto.png',
  },
];

export const RELIGION_ET_SPIRITUALITE = [
  {
    id: '40',
    name: 'Max Weber',
    thumbnailUrl: '/images/portraits/max_weber.png',
  },
  {
    id: '41',
    name: 'René Descartes',
    thumbnailUrl: '/images/portraits/rene_descartes.png',
  },
  {
    id: '42',
    name: 'Sadhguru',
    thumbnailUrl: '/images/portraits/sadhguru.png',
  },
];

export const SANTE_ET_BIEN_ETRE = [
  {
    id: '21',
    name: 'Michel Foucault',
    thumbnailUrl: '/images/portraits/michel_foucault.png',
  },
  {
    id: '44',
    name: 'Pasteur',
    thumbnailUrl: '/images/portraits/pasteur.png',
  },
  {
    id: '45',
    name: 'Pierre Rabhi',
    thumbnailUrl: '/images/portraits/pierre_rabhi.png',
  },
];

export const SCIENCE_ET_TECHNOLOGIE = [
  {
    id: '46',
    name: 'Bill Gates',
    thumbnailUrl: '/images/portraits/bill_gates.png',
  },
  {
    id: '47',
    name: 'Stephen Hawking',
    thumbnailUrl: '/images/portraits/stephen_hawking.png',
  },
  {
    id: '48',
    name: 'Elon Musk',
    thumbnailUrl: '/images/portraits/elon_musk.png',
  },
];

export const SOCIETE = [
  {
    id: '49',
    name: 'Amin Maalouf',
    thumbnailUrl: '/images/portraits/amin_maalouf.png',
  },
  {
    id: '50',
    name: 'Pierre Bourdieu',
    thumbnailUrl: '/images/portraits/pierre_bourdieu.png',
  },
  {
    id: '51',
    name: 'Frantz Fanon',
    thumbnailUrl: '/images/portraits/frantz_fanon.png',
  },
];
