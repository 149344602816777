import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { cn } from '~/utils/cn';

import CircularProgress from './components/CircularProgress';

function Loading({ onContinue }: { onContinue: () => void }) {
  const { t } = useTranslation();

  return (
    <motion.div
      className={cn(
        'relative flex rounded-xl shadow-lg justify-center',
        'md:h-[95vh] sm:h-[80vh] h-[80vh]',
        'xl:w-[40vw] lg:w-[50vw] md:w-[60vw] sm:w-[100vw] w-[100vw]'
      )}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5, ease: 'easeInOut' }}
    >
      <div
        className={cn(
          'flex flex-col items-center',
          'mx-6 mt-16',
          'xl:gap-6 lg:gap-4 md:gap-2 sm:gap-1 gap-1'
        )}
      >
        <CircularProgress />
        <h1
          className={cn(
            'font-nY font-bold letter-spacing-[-0.02em] text-center text-white',
            'lg:text-3xl md:text-2xl sm:text-xl text-xl',
            'lg:leading-8 md:leading-6 sm:leading-5 leading-5'
          )}
        >
          {t('onboarding.loadingStep.title')}
        </h1>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 1, duration: 0.5 }}
          className="flex flex-row gap-1"
        >
          <img
            src="/images/landing/check.svg"
            alt="check"
            className={cn(
              'md:text-xl sm:text-lg text-lg',
              'md:leading-6 sm:leading-5 leading-5'
            )}
          />
          <p
            className={cn(
              'font-sfProText font-weight-400 text-white text-opacity-60 letter-spacing-[0.03em] text-center',
              'md:text-xl sm:text-lg text-lg',
              'md:leading-6 sm:leading-5 leading-5'
            )}
          >
            {t('onboarding.loadingStep.firstDescription')}
          </p>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 2, duration: 0.5 }}
          className="flex flex-row gap-1"
        >
          <img
            src="/images/landing/check.svg"
            alt="check"
            className={cn(
              'md:text-xl sm:text-lg text-lg',
              'md:leading-6 sm:leading-5 leading-5'
            )}
          />
          <p
            className={cn(
              'font-sfProText font-weight-400 text-white text-opacity-60 letter-spacing-[0.03em] text-center',
              'md:text-xl sm:text-lg text-lg',
              'md:leading-6 sm:leading-5 leading-5'
            )}
          >
            {t('onboarding.loadingStep.secondDescription')}
          </p>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 3, duration: 0.5 }}
          className="flex flex-row gap-1"
        >
          <img
            src="/images/landing/check.svg"
            alt="check"
            className={cn(
              'md:text-xl sm:text-lg text-lg',
              'md:leading-6 sm:leading-5 leading-5'
            )}
          />
          <p
            className={cn(
              'font-sfProText font-weight-400 text-white text-opacity-60 letter-spacing-[0.03em] text-center',
              'md:text-xl sm:text-lg text-lg',
              'md:leading-6 sm:leading-5 leading-5'
            )}
          >
            {t('onboarding.loadingStep.thirdDescription')}
          </p>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 4, duration: 0.5 }}
          className="flex flex-row gap-1"
        >
          <img
            src="/images/landing/check.svg"
            alt="check"
            className={cn(
              'md:text-xl sm:text-lg text-lg',
              'md:leading-6 sm:leading-5 leading-5'
            )}
          />
          <p
            className={cn(
              'font-sfProText font-weight-400 text-white text-opacity-60 letter-spacing-[0.03em] text-center',
              'md:text-xl sm:text-lg text-lg',
              'md:leading-6 sm:leading-5 leading-5'
            )}
          >
            {t('onboarding.loadingStep.fourthDescription')}
          </p>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 6, duration: 0.5 }}
          className="absolute bottom-0 p-4 w-full"
        >
          <button
            className={cn(
              'font-inter font-medium text-black',
              'w-full bg-white rounded-md mt-4 p-2 z-10'
            )}
            onClick={onContinue}
          >
            {t('onboarding.button')}
          </button>
        </motion.div>
      </div>
    </motion.div>
  );
}

export default Loading;
