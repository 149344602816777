import { useTranslation } from 'react-i18next';

export function ChallengeList() {
  const { t } = useTranslation();

  return (
    <div
      className="hidden lg:flex flex-row mt-16 mb-16
                    xl:mx-32 lg:mx-32 md:mx-16 sm:mx-8 xs:mx-4 mx-4
                    h-[75vh]"
    >
      <div className="flex flex-col bg-white rounded-3xl border-[1px] border-solid border-white border-opacity-[6%] flex-[1.5] relative mr-6 pt-10 pl-10 pr-10">
        <div className="flex flex-[1] flex-col gap-2 pb-5 relative">
          <h1 className="font-nY font-bold text-5xl leading-8 text-left text-black md:text-5xl md:leading-10 lg:text-5xl lg:leading-10">
            {t('home.challenge.title')}
          </h1>
          <p className="font-sFProText font-normal text-16 text-left leading-22.4 text-black text-opacity-[70%] letter-spacing-[-0.03em] mt-6">
            {t('home.challenge.subtitle')}
          </p>
        </div>

        <div className="flex flex-[4] relative justify-center">
          <img
            className="absolute bottom-0 w-full h-full"
            src="/images/home/challengeList/illustration-challenge.png"
            alt=""
          />
        </div>
      </div>

      <div className="flex flex-col bg-background rounded-3xl border-[1px] border-solid border-white border-opacity-[6%] flex-[3.5] relative pt-10 pl-10 pr-10">
        <div className="flex flex-[1] flex-col gap-2 pb-5 relative">
          <h1 className="font-nY font-bold text-5xl leading-12 text-center text-white">
            {t('home.list.title')}
          </h1>
          <p className="font-sFProText font-normal text-16 text-center leading-22.4 text-white text-opacity-[70%] letter-spacing-[-0.03em] mt-6">
            {t('home.list.subtitle')}
          </p>
        </div>

        <div className="flex flex-[4] relative justify-center">
          <img
            className="absolute bottom-0 h-full h-full"
            src="/images/home/challengeList/illustration-list.png"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}
