import { motion } from 'framer-motion';
import React from 'react';

import { categoriesEnWithEmojis } from '~/data/categoriesEnWithEmojis';
import { categoriesFrWithEmojis } from '~/data/categoriesFrWithEmojis';
import type { Category } from '~/models/category';
import {
  useAllCategories,
  useAllCollections,
  useAllPersonalities,
  useDeselectCategory,
  useRemoveAllCollections,
  useRemoveAllPersonalities,
  useRemoveAllSelectedCollectionIds,
  useRemoveAllSelectedPersonalityIds,
  useSelectCategory,
  useSelectedCategories,
  useSelectedCollectionIds,
  useSelectedPersonalityIds,
} from '~/store/onboarding';
import { cn } from '~/utils/cn';
import useRootData from '~/utils/root';

function CategoriesStep(): JSX.Element {
  const { locale } = useRootData();

  const allCategories = useAllCategories();

  const categoriesWithEmojis =
    locale === 'fr' ? categoriesFrWithEmojis : categoriesEnWithEmojis;

  return (
    <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-2 p-4">
      {allCategories.map((category, index) => (
        <CategoryItem
          key={index}
          category={category}
          emoji={categoriesWithEmojis[index].emoji}
        />
      ))}
    </div>
  );
}

export default CategoriesStep;

/* components */
function CategoryItem({
  category,
  emoji,
}: {
  category: Category;
  emoji: string;
}): JSX.Element {
  const selectedCategories = useSelectedCategories();
  const selectCategory = useSelectCategory();
  const deselectCategory = useDeselectCategory();

  const allPersonalities = useAllPersonalities();
  const removeAllPersonalities = useRemoveAllPersonalities();
  const selectedPersonalityIds = useSelectedPersonalityIds();
  const removeAllSelectedPersonalityIds = useRemoveAllSelectedPersonalityIds();
  const allCollections = useAllCollections();
  const selectedCollectionIds = useSelectedCollectionIds();
  const removeAllCollections = useRemoveAllCollections();
  const removeSelectedCollectionIds = useRemoveAllSelectedCollectionIds();

  const isSelected = selectedCategories.some(
    (selectedCategory) => selectedCategory.id === category.id
  );

  const [selected, setSelected] = React.useState(isSelected);

  const onPress = React.useCallback(() => {
    setSelected(!selected);

    if (allPersonalities.length > 0 || selectedPersonalityIds.length > 0) {
      removeAllPersonalities();
      removeAllSelectedPersonalityIds();
    }

    if (allCollections.length > 0 || selectedCollectionIds.length > 0) {
      removeAllCollections();
      removeSelectedCollectionIds();
    }

    if (isSelected) {
      deselectCategory(category.id);
    } else {
      selectCategory(category);
    }
  }, [
    selected,
    category,
    selectCategory,
    deselectCategory,
    isSelected,
    allPersonalities,
    removeAllPersonalities,
    selectedPersonalityIds,
    removeAllSelectedPersonalityIds,
    allCollections,
    selectedCollectionIds,
    removeAllCollections,
    removeSelectedCollectionIds,
  ]);

  return (
    <motion.div
      className="flex flex-col items-center p-2 gap-2 transition-shadow duration-300"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5, ease: 'easeInOut' }}
    >
      <button
        className={cn(
          'flex flex-col justify-center items-center',
          'w-24 h-24 rounded-[50%] bg-[#252525] hover:bg-[#404040]',
          'select-none cursor-pointer',
          selected && 'border-[3px] border-white bg-[#404040]'
        )}
        onClick={onPress}
      >
        <p className="font-inter text-4xl select-none">{emoji}</p>
      </button>
      <p
        className={cn(
          'font-inter text-primary text-sm font-normal leading-20 text-center select-none',
          selected && 'text-white'
        )}
      >
        {category.name}
      </p>
    </motion.div>
  );
}
