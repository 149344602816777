import { useTranslation } from 'react-i18next';

export function FirstElements() {
  const { t } = useTranslation();

  return (
    <div
      className="flex flex-col w-5/6 mx-auto max-w-[1264px]
                items-center justify-center mt-16 space-y-3"
    >
      <h1
        className="font-nY font-bold text-4xl leading-10 text-center text-white
                  md:text-4xl md:leading-10 lg:text-5xl lg:leading-10"
      >
        {t('home.firstElements.title')}
      </h1>
      <p
        className="font-sFProDisplay font-normal text-md leading-5 text-center text-white
                  xs:text-sm xs:leading-5 sm:text-lg sm:leading-7
                  md:text-xl md:leading-9 lg:text-2xl lg:leading-9"
      >
        {t('home.firstElements.subtitle')}
      </p>
    </div>
  );
}
