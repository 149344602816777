export const BUSINESS_STRATEGY = [
  {
    id: '1',
    name: 'Michael Porter',
    thumbnailUrl: '/images/portraits/michael_porter.png',
  },
  {
    id: '2',
    name: 'Peter Drucker',
    thumbnailUrl: '/images/portraits/peter_drucker.png',
  },
  {
    id: '3',
    name: 'Jim Collins',
    thumbnailUrl: '/images/portraits/jim_collins.png',
  },
];

export const START_UP = [
  {
    id: '4',
    name: 'Bill Gates',
    thumbnailUrl: '/images/portraits/bill_gates.png',
  },
  {
    id: '5',
    name: 'Steve Jobs',
    thumbnailUrl: '/images/portraits/steve_jobs.png',
  },
  {
    id: '6',
    name: 'Walter Isaacson',
    thumbnailUrl: '/images/portraits/walter_isaacson.png',
  },
];

export const NEW_TECHNOLOGY = [
  {
    id: '7',
    name: 'Elon Musk',
    thumbnailUrl: '/images/portraits/elon_musk.png',
  },
  {
    id: '8',
    name: 'Mark Zuckerberg',
    thumbnailUrl: '/images/portraits/mark_zuckerberg.png',
  },
  {
    id: '9',
    name: 'David Packard',
    thumbnailUrl: '/images/portraits/david_packard.png',
  },
];

export const CAREER = [
  {
    id: '10',
    name: 'John Medina',
    thumbnailUrl: '/images/portraits/john_medina.png',
  },
  {
    id: '11',
    name: 'Adam Grant',
    thumbnailUrl: '/images/portraits/adam_grant.png',
  },
  {
    id: '12',
    name: 'Larry Myler',
    thumbnailUrl: '/images/portraits/larry_myler.png',
  },
];

export const LEADERSHIP = [
  {
    id: '13',
    name: 'Simon Sinek',
    thumbnailUrl: '/images/portraits/simon_sinek.png',
  },
  {
    id: '14',
    name: 'John C. Maxwell',
    thumbnailUrl: '/images/portraits/john_c_maxwell.png',
  },
  {
    id: '15',
    name: 'Jan Carlzon',
    thumbnailUrl: '/images/portraits/jan_carlzon.png',
  },
];

export const COMMUNICATION = [
  {
    id: '16',
    name: 'Dale Carnegie',
    thumbnailUrl: '/images/portraits/dale_carnegie.png',
  },
  {
    id: '17',
    name: 'Dan Roam',
    thumbnailUrl: '/images/portraits/dan_roam.png',
  },
  {
    id: '18',
    name: 'Carmine Gallo',
    thumbnailUrl: '/images/portraits/carmine_gallo.png',
  },
];

export const ENTREPRENEURSHIP = [
  {
    id: '19',
    name: 'Gary Vaynerchuk',
    thumbnailUrl: '/images/portraits/gary_vaynerchuk.png',
  },
  {
    id: '20',
    name: 'Guy Kawasaki',
    thumbnailUrl: '/images/portraits/guy_kawasaki.png',
  },
  {
    id: '21',
    name: 'Richard Branson',
    thumbnailUrl: '/images/portraits/richard_branson.png',
  },
];

export const PERSONAL_GROWTH = [
  {
    id: '22',
    name: 'Tony Robbins',
    thumbnailUrl: '/images/portraits/tony_robbins.png',
  },
  {
    id: '23',
    name: 'Deepak Chopra',
    thumbnailUrl: '/images/portraits/deepak_chopra.png',
  },
  {
    id: '24',
    name: 'Seth Godin',
    thumbnailUrl: '/images/portraits/seth_godin.png',
  },
];

export const BUSINESS_MANAGEMENT = [
  {
    id: '2',
    name: 'Peter Drucker',
    thumbnailUrl: '/images/portraits/peter_drucker.png',
  },
  {
    id: '26',
    name: 'Jack Welch',
    thumbnailUrl: '/images/portraits/jack_welch.png',
  },
  {
    id: '27',
    name: 'Clayton Christensen',
    thumbnailUrl: '/images/portraits/clayton_christensen.png',
  },
];

export const BIOGRAPHY = [
  {
    id: '28',
    name: 'Donald Trump',
    thumbnailUrl: '/images/portraits/donald_trump.png',
  },
  {
    id: '29',
    name: 'Abraham Lincoln',
    thumbnailUrl: '/images/portraits/abraham_lincoln.png',
  },
  {
    id: '5',
    name: 'Steve Jobs',
    thumbnailUrl: '/images/portraits/steve_jobs.png',
  },
];

export const FINANCE_AND_WEALTH = [
  {
    id: '30',
    name: 'Warren Buffett',
    thumbnailUrl: '/images/portraits/warren_buffett.png',
  },
  {
    id: '31',
    name: 'Martin Fridson',
    thumbnailUrl: '/images/portraits/martin_fridson.png',
  },
  {
    id: '32',
    name: 'Robert Kiyosaki',
    thumbnailUrl: '/images/portraits/robert_kiyosaki.png',
  },
];

export const MARKETING = [
  {
    id: '24',
    name: 'Seth Godin',
    thumbnailUrl: '/images/portraits/seth_godin.png',
  },
  {
    id: '33',
    name: 'Tom Hopkins',
    thumbnailUrl: '/images/portraits/tom_hopkins.png',
  },
  {
    id: '34',
    name: 'Daniel Pink',
    thumbnailUrl: '/images/portraits/daniel_pink.png',
  },
];

export const PRODUCTIVITY = [
  {
    id: '35',
    name: 'Stephen Covey',
    thumbnailUrl: '/images/portraits/stephen_covey.png',
  },
  {
    id: '36',
    name: 'Cal Newport',
    thumbnailUrl: '/images/portraits/cal_newport.png',
  },
  {
    id: '37',
    name: 'James Clear',
    thumbnailUrl: '/images/portraits/james_clear.png',
  },
];

export const MANAGEMENT = [
  {
    id: '38',
    name: 'Daryl Conner',
    thumbnailUrl: '/images/portraits/daryl_conner.png',
  },
  {
    id: '39',
    name: 'Gary Hamel',
    thumbnailUrl: '/images/portraits/gary_hamel.png',
  },
  {
    id: '40',
    name: 'Jeff Bezos',
    thumbnailUrl: '/images/portraits/jeff_bezos.png',
  },
];
