import { useLoaderData } from '@remix-run/react';
import { motion } from 'framer-motion';
import React from 'react';

import type { loader } from '~/routes/_index';
import {
  useAddAllBooks,
  useAllBooks,
  useSelectBookId,
} from '~/store/onboarding';
import { cn } from '~/utils/cn';

function BooksStep({ onFinish }: { onFinish: () => void }): JSX.Element {
  const { books: strapiBooks } = useLoaderData<typeof loader>();

  const allBooks = useAllBooks();
  const addAllBooks = useAddAllBooks();
  const selectBookId = useSelectBookId();

  const [currentIndex, setCurrentIndex] = React.useState(0);

  React.useEffect(() => {
    if (strapiBooks.length > 0 && allBooks.length === 0) {
      addAllBooks(strapiBooks);
    }
  }, [strapiBooks, allBooks, addAllBooks]);

  const onPressLike = React.useCallback(
    (id: string) => {
      selectBookId(id);

      if (currentIndex === allBooks.length - 1) {
        onFinish();
      } else {
        setCurrentIndex(currentIndex + 1);
      }
    },
    [allBooks, currentIndex, selectBookId, onFinish]
  );

  const onPressDislike = React.useCallback(() => {
    if (currentIndex === allBooks.length - 1) {
      onFinish();
    } else {
      setCurrentIndex(currentIndex + 1);
    }
  }, [allBooks, currentIndex, onFinish]);

  return (
    <div className="flex justify-center">
      {allBooks.length > 0 ? (
        <motion.div
          className={cn('flex flex-col justify-center p-4 gap-4')}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, ease: 'easeInOut' }}
        >
          <div className="flex items-center justify-center">
            <img
              className={cn(
                'flex select-none pointer-events-none',
                'rounded-[4.18px] border-[1px] border-solid border-white border-opacity-[6%]',
                'md:w-4/5 sm:w-3/5 w-3/5'
              )}
              src={allBooks[currentIndex].coverUrl}
              alt={allBooks[currentIndex].title}
            />
          </div>
          <div className="flex justify-evenly">
            <button
              className="select-none cursor-pointer hover:opacity-70"
              onClick={onPressDislike}
            >
              <img
                src="/images/landing/dislike.svg"
                alt="dislike"
                className="select-none pointer-events-none"
              />
            </button>
            <button
              className="select-none cursor-pointer hover:opacity-70"
              onClick={() => onPressLike(allBooks[currentIndex].id)}
            >
              <img
                src="/images/landing/like.svg"
                alt="like"
                className="select-none pointer-events-none"
              />
            </button>
          </div>
        </motion.div>
      ) : (
        <BookSkeleton />
      )}
    </div>
  );
}

export default BooksStep;

/* components */
function BookSkeleton(): JSX.Element {
  return (
    <motion.div
      className="flex justify-center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.2, ease: 'easeInOut' }}
    >
      <div className={cn('flex flex-col justify-center p-4 gap-4')}>
        <div
          className={cn(
            'flex select-none pointer-events-none',
            'rounded-[4.18px] border-[1px] border-solid border-white border-opacity-[6%]',
            'md:h-96 sm:h-72 h-72',
            'md:w-56 sm:w-56 w-56 bg-[#252525]'
          )}
        />
        <div className="flex justify-evenly">
          <div className="select-none cursor-pointer">
            <div className="select-none pointer-events-none" />
          </div>
          <div className="select-none cursor-pointer">
            <div className="select-none pointer-events-none" />
          </div>
        </div>
      </div>
    </motion.div>
  );
}
