import { useTranslation } from 'react-i18next';

export function MiniNews() {
  const { t } = useTranslation();

  return (
    <div
      className="relative show lg:hidden flex flex-col mt-16 mb-16
                md:mx-16 sm:mx-8 xs:mx-4 mx-4
                bg-background rounded-3xl border-[1px] border-solid border-white border-opacity-[6%]"
    >
      <div className="relative flex flex-[1] flex-col justify-center">
        <div
          className="relative flex flex-[1] flex-col
                    md:gap-2 sm:gap-2 xs:gap-1 gap-1
                    md:p-8 sm:p-6 xs:p-4 p-4"
        >
          <h1
            className="font-nY font-bold text-white text-center letter-spacing-[0.02em]
                      md:text-4xl sm:text-3xl xs:text-2xl text-2xl
                      md:leading-10 sm:leading-8 xs:leading-7 leading-7"
          >
            {t('home.news.title')}
          </h1>
          <h3
            className="font-sfProText font-normal text-white text-center text-opacity-[70%] letter-spacing-[-0.03em]
                      md:text-xl sm:text-lg xs:text-lg text-lg
                      md:leading-6 sm:leading-5 xs:leading-5 leading-5
                      mt-6 mx-4"
          >
            {t('home.news.subtitle')}
          </h3>

          <div
            className="absolute flex justify-center items-center
                      md:top-[26px] sm:top-[20px] xs:top-[10px] top-[10px]
                      md:left-[236px] sm:left-[200px] xs:left-[200px] left-[130px]"
          >
            <span
              className="font-sFProText font-normal
                        md:text-5xl sm:text-4xl xs:text-4xl text-4xl
                        md:leading-10 sm:leading-8 xs:leading-8 leading-8
                        rotate-[-90deg]"
            >
              🔍
            </span>
          </div>
        </div>

        <div
          className="flex flex-[4] justify-center
                   rounded-bl-3xl rounded-br-3xl"
        >
          <img
            className="h-full rounded-bl-3xl rounded-br-3xl"
            src="/images/home/news/mini-categories.png"
            alt="mini-categories"
          />
        </div>
      </div>
    </div>
  );
}
