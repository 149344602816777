import React from 'react';

import { cn } from '../utils/cn';

interface BackIconProps {
  className?: string;
}

const BackIcon: React.FC<BackIconProps> = ({ className }: BackIconProps) => (
  <svg fill="none" viewBox="0 0 20 20" className={cn('w-5 h-5', className)}>
    <path
      d="M11.6665 15L6.6665 10L11.6665 5L12.554 5.8875L8.4415 10L12.554 14.1125L11.6665 15Z"
      fill="white"
    />
  </svg>
);

export default BackIcon;
