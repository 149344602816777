import { useTranslation } from 'react-i18next';

export function NewsElements() {
  return (
    <div
      className="relative hidden lg:flex mt-16 mb-16
                xl:flex-row lg:flex-row md:flex-col sm:flex-col xs:flex-col flex-col
                xl:mx-32 lg:mx-32 md:mx-16 sm:mx-8 xs:mx-4 mx-4
                xl:h-[75vh] lg:h-[75vh] md:h-[64vh] sm:h-[64vh] h-[64vh]"
    >
      <News />
      <Notif />
    </div>
  );
}

function News() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-row bg-background rounded-3xl border-[1px] border-solid border-white border-opacity-[6%] mr-6 flex-[3.5]">
      <div className="flex flex-[2] flex-col p-10 rounded-tl-3xl rounded-bl-3xl justify-center relative">
        <h1 className="font-nY font-bold text-5xl leading-12 text-left text-white">
          {t('home.news.title')}
        </h1>
        <p className="font-sFProText font-normal text-16 text-left leading-22.4 text-white text-opacity-[70%] letter-spacing-[-0.03em] mt-6">
          {t('home.news.subtitle')}
        </p>

        <div className="flex h-[80px] w-[80px] justify-center items-center relative top-[-41%] left-[42%]">
          <span className="font-sFProText font-normal text-6xl leading-10 rotate-[-90deg]">
            🔍
          </span>
        </div>
      </div>

      <div className="flex flex-[2] rounded-tr-3xl rounded-br-3xl">
        <img
          className="object-cover h-full w-full rounded-tr-3xl rounded-br-3xl"
          src="/images/home/news/illustration-categories.png"
          alt=""
        />
      </div>
    </div>
  );
}

function Notif() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col bg-background rounded-3xl border-[1px] border-solid border-white border-opacity-[6%] flex-[1.5] relative">
      <div className="flex flex-[1] flex-col gap-4 p-10 relative">
        <div className="flex absolute top-[30px] left-[30px] h-4 w-4 bg-dot p-1 gap-2 rounded-full items-center justify-center">
          <p className="font-sFProText text-white text-center text-[12px] leading-[12px]">
            1
          </p>
        </div>

        <h1 className="font-nY font-bold text-5xl leading-8 text-left text-white md:text-5xl md:leading-10 lg:text-5xl lg:leading-10">
          {t('home.notif.title')}
        </h1>
        <p className="font-sFProText font-normal text-16 text-left leading-22.4 text-white text-opacity-[70%] letter-spacing-[-0.03em] mt-6">
          {t('home.notif.subtitle')}
        </p>
      </div>

      <div className="flex flex-[2] relative rounded-bl-3xl rounded-br-3xl">
        <img
          className="h-full absolute bottom-0 left-[17%] w-[66%]"
          src="/images/home/news/illustration-notif.png"
          alt=""
        />
      </div>
    </div>
  );
}
