import { useTranslation } from 'react-i18next';

import { cn } from '~/utils/cn';

function FooterStep({
  onPress,
  disabled,
}: {
  onPress: () => void;
  disabled: boolean;
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="absolute bottom-0 p-4 w-full">
      <button
        className={cn(
          'font-inter font-medium',
          'w-full bg-white text-black rounded-md mt-4 p-2',
          disabled && 'opacity-50 cursor-not-allowed'
        )}
        onClick={onPress}
        disabled={disabled}
      >
        {t('onboarding.button')}
      </button>
    </div>
  );
}

export default FooterStep;
