import { motion } from 'framer-motion';
import React from 'react';

import {
  type Personality,
  useAllPersonalities,
  useDeselectPersonalityId,
  useSelectPersonalityId,
  useSelectedPersonalityIds,
} from '~/store/onboarding';
import { cn } from '~/utils/cn';

function PersonalitiesStep(): JSX.Element {
  const allPersonalities = useAllPersonalities();

  return (
    <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-2 p-4">
      {allPersonalities.map((personality, index) => (
        <PersonalityItem key={index} personality={personality} />
      ))}
    </div>
  );
}

export default PersonalitiesStep;

/* components */
function PersonalityItem({
  personality,
}: {
  personality: Personality;
}): JSX.Element {
  const selectedPersonalityIds = useSelectedPersonalityIds();
  const selectPersonalityId = useSelectPersonalityId();
  const deselectPersonalityId = useDeselectPersonalityId();

  const isSelected = selectedPersonalityIds.some(
    (selectedPersonalityId) => selectedPersonalityId === personality.id
  );

  const [selected, setSelected] = React.useState(isSelected);

  const onPress = React.useCallback(() => {
    setSelected(!selected);

    if (isSelected) {
      deselectPersonalityId(personality.id);
    } else {
      selectPersonalityId(personality.id);
    }
  }, [
    selected,
    personality,
    isSelected,
    selectPersonalityId,
    deselectPersonalityId,
  ]);

  return (
    <motion.div
      className="flex flex-col items-center p-2 transition-shadow duration-300"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5, ease: 'easeInOut' }}
    >
      <button
        className={cn(
          'flex flex-col justify-center items-center',
          'w-24 h-24 rounded-[50%] bg-[#252525] hover:bg-[#404040]',
          'select-none cursor-pointer border-[3px] border-transparent',
          selected && 'border-white bg-[#404040]'
        )}
        onClick={onPress}
      >
        <img
          src={personality.thumbnailUrl}
          alt={personality.name}
          className="w-full h-full object-cover rounded-[50%] select-none pointer-events-none"
        />
      </button>
      <p
        className={cn(
          'font-inter text-primary text-sm font-normal leading-20 text-center select-none',
          selected && 'text-white'
        )}
      >
        {personality.name}
      </p>
    </motion.div>
  );
}
