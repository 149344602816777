import { Link, useLoaderData } from '@remix-run/react';
import { useEffect, useRef } from 'react';

import type { loader } from '~/routes/_index';
import useRootData from '~/utils/root';

export function FirstFiveBooks() {
  const { firstBooks } = useLoaderData<typeof loader>();
  const { locale } = useRootData();

  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const scrollElement = scrollRef.current;
    if (scrollElement) {
      const center =
        (scrollElement.scrollWidth - scrollElement.clientWidth) / 2;
      scrollElement.scrollTo({
        left: center,
        behavior: 'smooth',
      });
    }
  }, []);

  return (
    <div className="container max-w-full mt-16">
      <div
        ref={scrollRef}
        className="flex overflow-x-auto space-x-4 scrollbar-none scroll-touch justify-center py-4"
      >
        {firstBooks.map((book, index) => (
          <Link
            key={book.id}
            className={`${
              index % 2 === 0
                ? 'lg:mt-[10vh] md:mt-[8vh] sm:mt-[6vh] xs:mt-[4vh] mt-[4vh]'
                : 'lg:mb-[10vh] md:mb-[8vh] sm:mb-[6vh] xs:mb-[4vh] mb-[4vh]'
            } shadow-2xl flex-none
            xl:w-1/6 lg:w-1/5 md:w-1/4 sm:w-1/3 xs:w-1/3 w-1/3 
            rounded-[4.18px] border-[1px] border-solid border-white border-opacity-[6%]
            cursor-pointer hover:scale-105 transition-transform duration-300 ease-in-out
            `}
            to={`/${book.authorSlug}/${book.slug}?lng=${locale}`}
          >
            <img
              src={book.coverUrl}
              alt={book.title}
              className="rounded-[4.18px] w-full object-cover"
            />
          </Link>
        ))}
      </div>
    </div>
  );
}
