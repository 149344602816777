import { motion } from 'framer-motion';

import type { Locale } from '~/modules/language/@types/Locales';
import { cn } from '~/utils/cn';

import type { Landings } from '../OnboardingModal';

function LandingOne({
  content,
  locale,
}: {
  content: Landings;
  locale: Locale;
}) {
  return (
    <motion.div
      className="select-none"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5, ease: 'easeInOut' }}
    >
      <div className="flex flex-col mb-4">
        <h2
          className={cn(
            'font-nY text-left text-white',
            'xl:text-3xl lg:text-3xl md:text-2xl sm:text-xl text-xl',
            'xl:leading-7 lg:leading-7 md:leading-5 sm:leading-5 xs:leading-4 leading-4'
          )}
        >
          {content.title}
        </h2>
        {locale === 'fr' ? (
          <h2
            className={cn(
              'font-nY text-left text-white',
              'xl:text-3xl lg:text-3xl md:text-2xl sm:text-xl text-xl',
              'xl:leading-7 lg:leading-7 md:leading-5 sm:leading-5 xs:leading-4 leading-4'
            )}
          >
            {content.titleTwo}
          </h2>
        ) : null}
        <h2
          className={cn(
            'font-nY font-bold italic text-left text-white',
            'xl:text-3xl lg:text-3xl md:text-2xl sm:text-xl text-xl',
            'xl:leading-7 lg:leading-7 md:leading-5 sm:leading-5 xs:leading-4 leading-4'
          )}
        >
          {content.titleBold}
        </h2>
      </div>
      <div className="mb-4 text-left">
        <p
          className={cn(
            'font-sFProText text-left text-white',
            'xl:text-lg lg:text-lg md:text-md sm:text-sm text-sm',
            'xl:leading-5 lg:leading-5 md:leading-4 sm:leading-3 xs:leading-3 leading-3',
            'inline'
          )}
        >
          {content.description}
        </p>
        <p
          className={cn(
            'font-sFProText font-bold text-left text-white',
            'xl:text-lg lg:text-lg md:text-md sm:text-sm text-sm',
            'xl:leading-5 lg:leading-5 md:leading-4 sm:leading-3 xs:leading-3 leading-3',
            'inline'
          )}
        >
          {content.descriptionBold}
        </p>
        <p
          className={cn(
            'font-sFProText text-left text-white',
            'xl:text-lg lg:text-lg md:text-md sm:text-sm text-sm',
            'xl:leading-5 lg:leading-5 md:leading-4 sm:leading-3 xs:leading-3 leading-3',
            'z'
          )}
        >
          {content.descriptionEnd}
        </p>
      </div>
    </motion.div>
  );
}

export default LandingOne;
