import { motion, useAnimation } from 'framer-motion';
import React from 'react';

import { CheckAnimation } from './CheckAnimation';

const DURATION = 6000;
const INTERVAL_TIME = 10;

function CircularProgress() {
  const [percentage, setPercentage] = React.useState(0);
  const [isComplete, setIsComplete] = React.useState(false);

  const radius = 50;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (percentage / 100) * circumference;

  const scaleControls = useAnimation();

  const startCheckAnimation = React.useCallback(async () => {
    await scaleControls
      .start({
        scale: [1.3, 0],
        transition: { duration: 0.3 },
      })
      .then(() => setIsComplete(true));
  }, [scaleControls]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setPercentage((prev) => {
        const newPercentage = prev + 100 / (DURATION / INTERVAL_TIME);

        if (newPercentage >= 100) {
          clearInterval(interval);
          startCheckAnimation();

          return 100;
        }
        return newPercentage;
      });
    }, INTERVAL_TIME);

    return () => clearInterval(interval);
  }, [startCheckAnimation]);

  return (
    <div className="relative flex items-center justify-center">
      <svg className="w-32 h-32 relative" viewBox="0 0 150 150">
        <circle
          className="rgba(127, 127, 127, 0.2)"
          strokeWidth="5"
          stroke="rgba(127, 127, 127, 0.2)"
          fill="transparent"
          r={radius}
          cx="75"
          cy="75"
        />
        <circle
          className="#FFFFFF"
          strokeWidth="5"
          strokeLinecap="round"
          stroke="#FFFFFF"
          fill="transparent"
          r={radius}
          cx="75"
          cy="75"
          transform="rotate(-90 75 75)"
          style={{
            strokeDasharray: circumference,
            strokeDashoffset: offset,
            transition: 'stroke-dashoffset 0.1s',
          }}
        />
      </svg>

      {!isComplete ? (
        <motion.span
          initial={{ scale: 1 }}
          animate={scaleControls}
          className="absolute font-inter font-semibold text-white text-2xl"
        >
          {Math.floor(percentage)}%
        </motion.span>
      ) : null}

      <CheckAnimation enableAnimation={isComplete} />
    </div>
  );
}

export default CircularProgress;
