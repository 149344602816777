import { useLoaderData } from '@remix-run/react';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSwipeable } from 'react-swipeable';

import type { loader } from '~/routes/_index';
import CheckoutPage from '~/routes/CheckoutPage';
import { useSetOnboardingFinished } from '~/store/onboarding';

import { useBodyScrollLock } from '../../../hooks/useBodyScrollLock';
import { cn } from '../../../utils/cn';

import LandingOne from './LandingOne/LandingOne';
import LandingThree from './LandingThree/LandingThree';
import LandingTwo from './LandingTwo/LandingTwo';
import Loading from './Loading/Loading';
import Login from './Login/Login';
import Steps from './Steps/Steps';

export interface Landings {
  title: string;
  titleTwo?: string;
  titleBold?: string;
  description: string;
  descriptionBold?: string;
  descriptionEnd?: string;
  descriptionTwo?: string;
  descriptionTwoBold?: string;
  descriptionThree?: string;
  descriptionThreeBold?: string;
  descriptionFour?: string;
  descriptionFourBold?: string;
  descriptionFive?: string;
  nextDescription?: string;
  nextDescriptionBold?: string;
  nextDescriptionTwo?: string;
  nextDescriptionTwoBold?: string;
  nextDescriptionThree?: string;
  nextDescriptionThreeBold?: string;
  nextDescriptionFour?: string;
  source: string;
}

const OnboardingModal: React.FC = () => {
  const data = useLoaderData<typeof loader>();
  const { locale } = data;
  const { t } = useTranslation();

  const [step, setStep] = useState(0);

  const landings: Landings[] = [
    {
      title: t('onboarding.landingOne.title'),
      titleTwo: t('onboarding.landingOne.titleTwo'),
      titleBold: t('onboarding.landingOne.titleBold'),
      description: t('onboarding.landingOne.description'),
      descriptionBold: t('onboarding.landingOne.descriptionBold'),
      descriptionEnd: t('onboarding.landingOne.descriptionEnd'),
      source: '/images/landing/background-one.png',
    },
    {
      title: t('onboarding.landingTwo.title'),
      description: t('onboarding.landingTwo.description'),
      descriptionBold: t('onboarding.landingTwo.descriptionBold'),
      descriptionTwo: t('onboarding.landingTwo.descriptionTwo'),
      descriptionTwoBold: t('onboarding.landingTwo.descriptionTwoBold'),
      descriptionThree: t('onboarding.landingTwo.descriptionThree'),
      descriptionThreeBold: t('onboarding.landingTwo.descriptionThreeBold'),
      descriptionFour: t('onboarding.landingTwo.descriptionFour'),
      descriptionFourBold: t('onboarding.landingTwo.descriptionFourBold'),
      nextDescription: t('onboarding.landingTwo.nextDescription'),
      nextDescriptionBold: t('onboarding.landingTwo.nextDescriptionBold'),
      nextDescriptionTwo: t('onboarding.landingTwo.nextDescriptionTwo'),
      nextDescriptionTwoBold: t('onboarding.landingTwo.nextDescriptionTwoBold'),
      nextDescriptionThree: t('onboarding.landingTwo.nextDescriptionThree'),
      nextDescriptionThreeBold: t(
        'onboarding.landingTwo.nextDescriptionThreeBold'
      ),
      nextDescriptionFour: t('onboarding.landingTwo.nextDescriptionFour'),
      source: '/images/landing/background-two.png',
    },
    {
      title: t('onboarding.landingThree.title'),
      description: t('onboarding.landingThree.description'),
      descriptionBold: t('onboarding.landingThree.descriptionBold'),
      descriptionTwo: t('onboarding.landingThree.descriptionTwo'),
      descriptionThree: t('onboarding.landingThree.descriptionThree'),
      descriptionThreeBold: t('onboarding.landingThree.descriptionThreeBold'),
      descriptionFour: t('onboarding.landingThree.descriptionFour'),
      descriptionFourBold: t('onboarding.landingThree.descriptionFourBold'),
      descriptionFive: t('onboarding.landingThree.descriptionFive'),
      source: '/images/landing/background-three.png',
    },
  ];

  const handleSwipeLeft = () => {
    if (step < landings.length - 1) {
      setStep(step + 1);
    }
  };

  const handleSwipeRight = () => {
    if (step > 0 && step < landings.length) {
      setStep(step - 1);
    }
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleSwipeLeft,
    onSwipedRight: handleSwipeRight,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  const onPress = () => {
    setStep(step + 1);
  };

  const onBackClick = () => {
    setStep(step - 1);
  };

  const onGoToLogin = () => {
    setStep(5);
  };

  useBodyScrollLock(true);

  const modalRef = React.useRef<HTMLDivElement | null>(null);

  const setOnboardingFinished = useSetOnboardingFinished();

  React.useEffect(() => {
    function handleClickOutside(event: { target: EventTarget | null }) {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        setOnboardingFinished();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [setOnboardingFinished]);

  return (
    <motion.div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 backdrop-blur-xl z-50 h-screen"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5, ease: 'easeInOut' }}
    >
      <div ref={modalRef}>
        <div
          className="relative bg-black rounded-xl mx-auto shadow-lg text-center"
          {...swipeHandlers}
        >
          {step < 3 && (
            <div
              className={cn(
                'relative md:h-[95vh] sm:h-[80vh] h-[80vh]',
                'xl:w-[40vw] lg:w-[50vw] md:w-[60vw] sm:w-[100vw] w-[100vw]'
              )}
            >
              <img
                src={landings[step].source}
                alt="landing one"
                className={cn(
                  'h-full w-full object-cover rounded-lg pointer-events-none select-none',
                  'opacity-60'
                )}
              />
              <div className="absolute top-0 left-0 w-full h-full">
                <div className="flex justify-center space-x-1 mx-2 mt-10">
                  {landings.map((_, index) => (
                    <span
                      key={index}
                      className={`h-[3px] w-1/3 rounded-full
                      ${index === step || index < step ? 'bg-white' : 'bg-white bg-opacity-50'}
                      transition-all duration-700 ease-in-out
                      `}
                    />
                  ))}
                </div>
              </div>
              <div className="absolute bottom-0 p-4 w-full">
                {step === 0 && (
                  <LandingOne locale={locale} content={landings[step]} />
                )}
                {step === 1 && <LandingTwo content={landings[step]} />}
                {step === 2 && <LandingThree content={landings[step]} />}
                <button
                  className="font-medium w-full bg-white text-black rounded-md mt-4 p-2"
                  onClick={onPress}
                >
                  {t('onboarding.button')}
                </button>
                <button
                  className="font-sFPro w-full text-white mt-4 p-1 text-sm underline"
                  onClick={onGoToLogin}
                >
                  {t('onboarding.registered')}
                </button>
              </div>
            </div>
          )}
          {step === 3 && <Steps onBackClick={onBackClick} onFinish={onPress} />}
          {step === 4 && <Loading onContinue={onPress} />}
          {step === 5 && <Login onContinue={onPress} />}
          {step === 6 && <CheckoutPage onClose={setOnboardingFinished} />}
        </div>
      </div>
    </motion.div>
  );
};

export default OnboardingModal;
