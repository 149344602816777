import {
  BIOGRAPHY,
  BUSINESS_MANAGEMENT,
  BUSINESS_STRATEGY,
  CAREER,
  COMMUNICATION,
  ENTREPRENEURSHIP,
  FINANCE_AND_WEALTH,
  LEADERSHIP,
  MANAGEMENT,
  MARKETING,
  NEW_TECHNOLOGY,
  PERSONAL_GROWTH,
  PRODUCTIVITY,
  START_UP,
} from '~/data/personalitiesEn';
import {
  ACTIVISME,
  ARGENT,
  ART,
  BIOGRAPHIES,
  BUSINESS,
  DEVELOPPEMENT_PERSONNEL,
  DROIT,
  HISTOIRE,
  NATURE_ET_ENVIRONNEMENT,
  PHILOSOPHIE,
  POLITIQUE,
  PSYCHOLOGIE,
  RELATIONS_ET_FAMILLE,
  RELIGION_ET_SPIRITUALITE,
  SANTE_ET_BIEN_ETRE,
  SCIENCE_ET_TECHNOLOGIE,
  SOCIETE,
} from '~/data/personalitiesFr';
import type { Category } from '~/models/category';
import type { Locale } from '~/modules/language/@types/Locales';
import type { Personality } from '~/store/onboarding';

export function useComputeAndAddPersonalities(
  categories: Category[],
  locale: Locale
): Personality[] {
  const personalities: Personality[] = [];

  if (locale === 'fr') {
    categories.map((category) => {
      if (category.name === 'Activisme') {
        personalities.push(...ACTIVISME);
      } else if (category.name === 'Argent') {
        personalities.push(...ARGENT);
      } else if (category.name === 'Art') {
        personalities.push(...ART);
      } else if (category.name === 'Biographies') {
        personalities.push(...BIOGRAPHIES);
      } else if (category.name === 'Business') {
        personalities.push(...BUSINESS);
      } else if (category.name === 'Développement personnel') {
        personalities.push(...DEVELOPPEMENT_PERSONNEL);
      } else if (category.name === 'Droit') {
        personalities.push(...DROIT);
      } else if (category.name === 'Histoire') {
        personalities.push(...HISTOIRE);
      } else if (category.name === 'Nature et environnement') {
        personalities.push(...NATURE_ET_ENVIRONNEMENT);
      } else if (category.name === 'Philosophie') {
        personalities.push(...PHILOSOPHIE);
      } else if (category.name === 'Politique') {
        personalities.push(...POLITIQUE);
      } else if (category.name === 'Psychologie') {
        personalities.push(...PSYCHOLOGIE);
      } else if (category.name === 'Relations et famille') {
        personalities.push(...RELATIONS_ET_FAMILLE);
      } else if (category.name === 'Religion et spiritualité') {
        personalities.push(...RELIGION_ET_SPIRITUALITE);
      } else if (category.name === 'Santé et bien-être') {
        personalities.push(...SANTE_ET_BIEN_ETRE);
      } else if (category.name === 'Science et technologie') {
        personalities.push(...SCIENCE_ET_TECHNOLOGIE);
      } else if (category.name === 'Société') {
        personalities.push(...SOCIETE);
      }
    });
  } else {
    categories.map((category) => {
      if (category.name === 'Biography') {
        personalities.push(...BIOGRAPHY);
      } else if (category.name === 'Business management') {
        personalities.push(...BUSINESS_MANAGEMENT);
      } else if (category.name === 'Business strategy') {
        personalities.push(...BUSINESS_STRATEGY);
      } else if (category.name === 'Career') {
        personalities.push(...CAREER);
      } else if (category.name === 'Communication') {
        personalities.push(...COMMUNICATION);
      } else if (category.name === 'Entrepreneurship') {
        personalities.push(...ENTREPRENEURSHIP);
      } else if (category.name === 'Finance and Wealth') {
        personalities.push(...FINANCE_AND_WEALTH);
      } else if (category.name === 'Leadership') {
        personalities.push(...LEADERSHIP);
      } else if (category.name === 'Management') {
        personalities.push(...MANAGEMENT);
      } else if (category.name === 'Marketing') {
        personalities.push(...MARKETING);
      } else if (category.name === 'New Technology') {
        personalities.push(...NEW_TECHNOLOGY);
      } else if (category.name === 'Personal growth') {
        personalities.push(...PERSONAL_GROWTH);
      } else if (category.name === 'Productivity') {
        personalities.push(...PRODUCTIVITY);
      } else if (category.name === 'Start-up') {
        personalities.push(...START_UP);
      }
    });
  }

  // Remove duplicates
  const uniquePersonalities = personalities.filter(
    (v, i, a) => a.findIndex((t) => t.id === v.id) === i
  );

  return uniquePersonalities;
}
