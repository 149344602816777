import { useTranslation } from 'react-i18next';

export function MiniChallenge() {
  const { t } = useTranslation();

  return (
    <div
      className="relative show lg:hidden flex flex-row mt-16 mb-16
                md:mx-16 sm:mx-8 xs:mx-4 mx-4
                bg-white rounded-3xl border-[1px] border-solid border-white border-opacity-[6%]"
    >
      <div className="relative flex flex-[1] flex-col justify-center">
        <div className="relative flex flex-[1] flex-col gap-4 p-10">
          <h1
            className="font-nY font-bold text-center text-black
                      md:text-5xl sm:text-4xl xs:text-3xl text-3xl
                      md:leading-10 sm:leading-8 xs:leading-7 leading-7"
          >
            {t('home.challenge.title')}
          </h1>
          <h3
            className="font-sFProText font-normal text-center text-black text-opacity-[70%] letter-spacing-[-0.03em]
                      md:text-2xl sm:text-xl xs:text-xl text-xl
                      md:leading-6 sm:leading-5 xs:leading-5 leading-5
                      mx-4"
          >
            {t('home.challenge.subtitle')}
          </h3>
        </div>

        <div className="flex flex-[4] justify-center mx-8">
          <img
            className="h-full"
            src="/images/home/challengeList/illustration-challenge.png"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}
