import {
  Catalogue,
  CategoryElements,
  ChallengeList,
  FirstElements,
  FirstFiveBooks,
  MiniChallenge,
  MiniList,
  MiniNews,
  MiniNotif,
  MiniPlayer,
  NewsElements,
  PlayerElement,
} from './components';

export default function Home() {
  return (
    <>
      <FirstElements />
      <FirstFiveBooks />
      <Catalogue />
      <PlayerElement />
      <MiniPlayer />
      <NewsElements />
      <MiniNews />
      <MiniNotif />
      <CategoryElements />
      <ChallengeList />
      <MiniChallenge />
      <MiniList />
    </>
  );
}
