import { Link, useLoaderData } from '@remix-run/react';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import type { loader } from '~/routes/_index';
import useRootData from '~/utils/root';

export function CategoryElements() {
  const { t } = useTranslation();
  const { categories } = useLoaderData<typeof loader>();
  const { locale } = useRootData();

  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const scrollElement = scrollRef.current;
    if (scrollElement) {
      const center =
        (scrollElement.scrollWidth - scrollElement.clientWidth) / 2;
      scrollElement.scrollTo({
        left: center,
        behavior: 'smooth',
      });
    }
  }, []);

  return (
    <div className="relative flex flex-col w-full mb-16">
      <div
        className="flex flex-col bg-background gap-22 h-[70vh]
                  xl:mx:32 lg:mx-32 md:mx-16 sm:mx-8 xs:mx-4 mx-4
                  border-[1px] border-solid border-white border-opacity-[6%]
                  rounded-tl-3xl rounded-tr-3xl"
      >
        <div
          className="flex flex-col
                    xl:gap-8 lg:gap-8 md:gap-6 sm:gap-6 xs:gap-4 gap-4
                    xl:pt-12 lg:pt-10 md:pt-10 sm:pt-10 xs:pt-10 pt-10 px-4"
        >
          <h1
            className="font-nY font-bold text-center text-white
                      xl:text-5xl lg:text-5xl md:text-4xl sm:text-3xl xs:text-2xl text-2xl
                      xl:leading-12 lg:leading-12 md:leading-10 sm:leading-8 xs:leading-7 leading-7 px-10"
          >
            {t('home.category.title')}
          </h1>
          <h3
            className="font-sFProText font-normal text-center text-white text-opacity-[70%]
                        2xl:text-3xl xl:text-xl lg:text-lg md:text-lg sm:text-lg xs:text-lg text-lg
                        2xl:leading-12 xl:leading-8 lg:leading-6 md:leading-6 sm:leading-5 xs:leading-5 leading-5
                        letter-spacing-[-0.03em]"
          >
            {t('home.category.subtitle')}
          </h3>
        </div>
      </div>
      <div className="absolute max-w-full bottom-0">
        <div
          ref={scrollRef}
          className="flex overflow-x-auto space-x-4 scrollbar-none scroll-touch overflow-y-hidden py-4"
        >
          {categories.map((category) => (
            <Link
              key={category.id}
              to={`/category/${category.slug}?lng=${locale}`}
              className="flex-none shadow-2xl h-[30vh] w-[30vh] gap-2 rounded-[8px] border-[1px] border-solid border-white border-opacity-[6%]
                        cursor-pointer hover:scale-105 transition-transform duration-300 ease-in-out"
            >
              <div className="relative h-full rounded-[8px]">
                <img
                  className="h-full rounded-[8px] object-cover"
                  src={category.coverUrl}
                  alt={category.name}
                />
                <h3
                  className="flex absolute bottom-0 font-inter font-bold pl-5 pb-5 text-left text-white
                            xl:text-3xl lg:text-3xl md:text-2xl sm:text-2xl xs:text-xl text-xl
                            xl:leading-8 lg:leading-8 md:leading-6 sm:leading-6 xs:leading-5 leading-5"
                >
                  {category.name}
                </h3>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}
