import { useTranslation } from 'react-i18next';

export function MiniNotif() {
  const { t } = useTranslation();

  return (
    <div
      className="relative show lg:hidden flex flex-col
                md:mx-16 sm:mx-8 xs:mx-4 mx-4 mb-16
                bg-background rounded-3xl border-[1px] border-solid border-white border-opacity-[6%]"
    >
      <div className="relative flex flex-[1] flex-col justify-center gap-4 p-10">
        <div className="absolute flex top-[30px] left-[100px] h-4 w-4 bg-dot p-1 gap-2 rounded-full items-center justify-center">
          <p className="font-sFProText text-white text-center text-[12px] leading-[12px]">
            1
          </p>
        </div>

        <h1
          className="font-nY font-bold text-center text-white mx-8
                    md:text-5xl sm:text-4xl xs:text-3xl text-3xl
                    md:leading-10 sm:leading-8 xs:leading-7 leading-7"
        >
          {t('home.notif.title')}
        </h1>
        <h3
          className="font-sFProText font-normal text-center text-white text-opacity-[70%] letter-spacing-[-0.03em]
                    md:text-2xl sm:text-xl xs:text-xl text-xl
                    md:leading-6 sm:leading-5 xs:leading-5 leading-5
                    mt-6 mx-4"
        >
          {t('home.notif.subtitle')}
        </h3>
      </div>

      <div
        className="flex flex-[2] justify-center
      rounded-bl-3xl rounded-br-3xl mx-8"
      >
        <img
          className="h-full"
          src="/images/home/news/illustration-notif.png"
          alt=""
        />
      </div>
    </div>
  );
}
