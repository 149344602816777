import { useFetcher } from '@remix-run/react';
import { signInWithPopup } from 'firebase/auth';
import { motion } from 'framer-motion';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Loader from '~/components/Loader';
import { useRefreshAuth } from '~/hooks/useRefreshAuth';
import {
  appleAuthProvider,
  auth,
  googleAuthProvider,
} from '~/libs/firebase.client';
import {
  useSelectedBookIds,
  useSelectedCategories,
  useSelectedCollectionIds,
  useSelectedPersonalityIds,
  useSetOnboardingFinished,
} from '~/store/onboarding';
import { cn } from '~/utils/cn';

function Login({ onContinue }: { onContinue: () => void }) {
  const { t } = useTranslation();
  const { refreshAuth } = useRefreshAuth();

  return (
    <motion.div
      className={cn(
        'relative flex flex-col rounded-xl shadow-lg items-center justify-center',
        'md:h-[95vh] sm:h-[80vh] h-[80vh]',
        'xl:w-[40vw] lg:w-[50vw] md:w-[60vw] sm:w-[100vw] w-[100vw]',
        'p-4 gap-16'
      )}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5, ease: 'easeInOut' }}
    >
      <div className="flex flex-col gap-2">
        <h1
          className={cn(
            'font-nY font-bold letter-spacing-[-0.02em] text-center text-white select-none',
            'lg:text-3xl md:text-2xl sm:text-xl text-xl',
            'lg:leading-8 md:leading-6 sm:leading-5 leading-5'
          )}
        >
          {t('login.title')}
        </h1>
        <h3 className="font-sFProDisplay font-[600] text-white text-center text-sm leading-5 select-none">
          {t('login.description')}
        </h3>
      </div>
      <div className="flex flex-col w-full gap-2 max-w-xs">
        <AppleButton onContinue={onContinue} refreshAuth={refreshAuth} />
        <GoogleButton onContinue={onContinue} refreshAuth={refreshAuth} />
      </div>
    </motion.div>
  );
}

export default Login;

interface ButtonProps {
  onContinue: () => void;
  refreshAuth: () => void;
}

function GoogleButton({ onContinue, refreshAuth }: ButtonProps) {
  const { t } = useTranslation();
  const setOnboardingFinished = useSetOnboardingFinished();

  const selectedCategories = useSelectedCategories();
  const selectedCollectionIds = useSelectedCollectionIds();
  const selectedPersonalityIds = useSelectedPersonalityIds();
  const selectedBookIds = useSelectedBookIds();

  const [isLoading, setIsLoading] = React.useState(false);

  const fetcher = useFetcher();

  const onClick = React.useCallback(async () => {
    setIsLoading(true);

    try {
      const result = await signInWithPopup(auth, googleAuthProvider);

      const user = result.user;
      const token = await user.getIdToken();

      try {
        await fetch('/google', {
          method: 'POST',
          headers: {
            'Cross-Origin-Opener-Policy': 'same-origin-allow-popups',
            'Cross-Origin-Embedder-Policy': 'require-corp',
          },
          body: JSON.stringify({
            token,
            fromOnboarding: true,
            selectedCategoryIds:
              selectedCategories.length > 0
                ? selectedCategories.map((category) => category.id)
                : [],
            selectedCollectionIds:
              selectedCollectionIds.length > 0 ? selectedCollectionIds : [],
            selectedPersonalityIds:
              selectedPersonalityIds.length > 0 ? selectedPersonalityIds : [],
            selectedBookIds: selectedBookIds.length > 0 ? selectedBookIds : [],
          }),
        });
      } catch (error) {
        setIsLoading(false);
        throw new Error(`[Auth Google] error on fetch: ${error}`);
      }
    } catch (error) {
      setIsLoading(false);
      throw new Error(`[Auth Google] error on signInWithPopup: ${error}`);
    }

    refreshAuth();

    const currentSubscription = await fetch('/stripe/subscription');
    const data = await currentSubscription.json();
    if (!data?.hasSubscription) {
      onContinue();
      return;
    }

    setOnboardingFinished();

    fetcher.submit(
      { isPremium: 'true' },
      {
        method: 'POST',
        action: '/update-session',
      }
    );
  }, [
    setOnboardingFinished,
    fetcher,
    selectedCategories,
    selectedCollectionIds,
    selectedPersonalityIds,
    selectedBookIds,
    onContinue,
    refreshAuth,
  ]);

  return (
    <button
      className={cn(
        'flex items-center justify-center cursor-pointer',
        'font-inter font-medium text-black',
        'w-full bg-white rounded-md p-2 gap-2'
      )}
      onClick={onClick}
    >
      <img
        src="/images/google-logo.svg"
        alt="Google login button"
        className="h-5 w-5 select-none pointer-events-none"
      />
      <p className="font-sFProDisplay font-[600] text-black text-sm leading-5">
        {t('login.google')}
      </p>
      {isLoading && <Loader />}
    </button>
  );
}

function AppleButton({ onContinue, refreshAuth }: ButtonProps) {
  const { t } = useTranslation();
  const setOnboardingFinished = useSetOnboardingFinished();

  const selectedCategories = useSelectedCategories();
  const selectedCollectionIds = useSelectedCollectionIds();
  const selectedPersonalityIds = useSelectedPersonalityIds();
  const selectedBookIds = useSelectedBookIds();

  const [isLoading, setIsLoading] = React.useState(false);

  const fetcher = useFetcher();

  const onClick = React.useCallback(async () => {
    setIsLoading(true);

    try {
      const result = await signInWithPopup(auth, appleAuthProvider);
      const user = result.user;
      const token = await user.getIdToken();

      try {
        await fetch('/apple', {
          method: 'POST',
          headers: {
            'Cross-Origin-Opener-Policy': 'same-origin-allow-popups',
            'Cross-Origin-Embedder-Policy': 'require-corp',
          },
          body: JSON.stringify({
            token,
            fromOnboarding: true,
            selectedCategoryIds:
              selectedCategories.length > 0
                ? selectedCategories.map((category) => category.id)
                : [],
            selectedCollectionIds:
              selectedCollectionIds.length > 0 ? selectedCollectionIds : [],
            selectedPersonalityIds:
              selectedPersonalityIds.length > 0 ? selectedPersonalityIds : [],
            selectedBookIds: selectedBookIds.length > 0 ? selectedBookIds : [],
          }),
        });
      } catch (error) {
        setIsLoading(false);
        throw new Error(`[Auth Apple] error on fetch: ${error}`);
      }
    } catch (error) {
      setIsLoading(false);
      throw new Error(`[Auth Apple] error on signInWithPopup: ${error}`);
    }

    refreshAuth();

    const currentSubscription = await fetch('/stripe/subscription');
    const data = await currentSubscription.json();
    if (!data?.hasSubscription) {
      onContinue();
      return;
    }

    setOnboardingFinished();

    fetcher.submit(
      { isPremium: 'true' },
      {
        method: 'POST',
        action: '/update-session',
      }
    );
  }, [
    setOnboardingFinished,
    fetcher,
    selectedCategories,
    selectedCollectionIds,
    selectedPersonalityIds,
    selectedBookIds,
    onContinue,
    refreshAuth,
  ]);

  return (
    <button
      className={cn(
        'flex items-center justify-center cursor-pointer',
        'font-inter font-medium text-black',
        'w-full bg-white rounded-md p-2 gap-2'
      )}
      onClick={onClick}
    >
      <img
        src="/images/apple-logo.svg"
        alt="Apple login button"
        className="h-5 w-5 select-none pointer-events-none"
      />
      <p className="font-sFProDisplay font-[600] text-black text-sm leading-5">
        {t('login.apple')}
      </p>
      {isLoading && <Loader />}
    </button>
  );
}
